

































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';

const parts: ('A' | 'B' | 'C' | 'D' | 'E')[] = ['A', 'B', 'C', 'D', 'E'];
export type UofTQuiz5Question1Part = (typeof parts)[number];

export type UofTQuiz5Question1TaskState = TaskState<{
  partAVersion: VariableValueType.IntegerValue;
  partBVersion: VariableValueType.IntegerValue;
  partCVersion: VariableValueType.IntegerValue;
  partDVersion: VariableValueType.IntegerValue;
  partEVersion: VariableValueType.IntegerValue;
}>;

export default defineComponent({
  name: 'UofTQuiz5Question1',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<UofTQuiz5Question1TaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        A: [] as string[],
        B: [] as string[],
        C: [] as string[],
        D: [] as string[],
        E: [] as string[],
      },
      imagePrefix: '/img/assignments/UofT/CHMB41H/2023/Quiz5/Question1/',
      questionParts: parts,
    };
  },
  computed: {
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    versions(): Partial<Record<UofTQuiz5Question1Part, number>> {
      return parts.reduce((acc: Partial<Record<UofTQuiz5Question1Part, number>>, part) => {
        acc[part] = this.taskState.getValueByName(`part${part}Version`)?.content?.value ?? 1;
        return acc;
      }, {});
    },
    choices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D', 'E', 'F'], this.seed);
    },
    images(): Partial<Record<UofTQuiz5Question1Part, string>> {
      return parts.reduce((acc: Partial<Record<UofTQuiz5Question1Part, string>>, part) => {
        acc[part] = `${this.imagePrefix}part${part}-v${this.versions[part]}.png`;
        return acc;
      }, {});
    },
    choiceImages(): Partial<Record<UofTQuiz5Question1Part, string[]>> {
      return parts.reduce((acc: Partial<Record<UofTQuiz5Question1Part, string[]>>, part) => {
        acc[part] = this.choices.map(
          (choice: string) =>
            `${this.imagePrefix}part${part}-v${this.versions[part]}-choice${choice}.png`
        );
        return acc;
      }, {});
    },
  },
});
